import { WalletService } from '@/api/wallet'
import { WALLET, queryClient } from '../utils'
import { IWallet } from '@/interface'
import { NativeBridge } from '@/utils/native_bridge'
//@ts-ignore
export interface IWalletSlice {
  wallets: IWallet[]
  wallet: null | IWallet
  is_loading: boolean
  resetWallets: Function
  setWalletBackedUp: Function
  loadWallets: () => Promise<void>
  createWallet: () => Promise<void>
  importWallet: Function
}
//@ts-ignore
export const WalletSlice = (set: any, ...params: any): IWalletSlice => ({
  wallets: [],
  wallet: null,
  is_loading: true,
  resetWallets: () => {
    set(() => ({
      wallets: [],
      wallet: null,
    }))
  },
  setWalletBackedUp: () => {
    set((state: any) => {
      state.wallet = {
        ...state.wallet,
        backedup_done: true,
      }
      state.wallets = state.wallets.map((_: any) => ({
        ..._,
        backedup_done: true,
      }))
      return {
        ...state,
      }
    })
  },
  loadWallets: async () => {
    try {
      const isNative = NativeBridge.isBridge()
      const { _id: userId } = queryClient.getQueryState(['user'])?.data as any
      set(() => ({
        is_loading: true,
      }))
      const walletsRes = await WalletService.GetAll()
      let nativeWalletPassphrase: any
      if (NativeBridge.isBridge()) {
        nativeWalletPassphrase = await NativeBridge.Send(
          'PK_STATUS',
          walletsRes.result.map((_: IWallet) => _.address)
        )
      }
      const localWallts = Object.values(WALLET.GetAll())
        .filter((_: any) => _.userId === userId)
        .reduce((obj: any, prop: any) => {
          obj[prop._id] = prop
          return obj
        }, {}) as any
      const wallets = (walletsRes?.result || []).map((wallet: any, i: number) => {
        return {
          ...wallet,
          is_local: isNative ? nativeWalletPassphrase[i] : wallet._id in localWallts,
          key: localWallts?.[wallet._id]?.key /** encrypted PK, only in the webapp */,
          userId,
        }
      })
      const wallet = wallets.find((wallet: IWallet) => wallet.isPrimary)

      set(() => ({
        wallets: wallets,
        is_loading: false,
        wallet,
      }))
    } catch (error) {
      console.log(error)
    }
  },
  createWallet: async () => {
    try {
      let wallet = await WALLET.Create()
      set((state: any) => ({
        wallets: [...state.wallets, { ...wallet, is_local: true }],
        wallet: { ...wallet, is_local: true },
      }))
    } catch (error) {
      console.log(error)
    }
  },
  importWallet: async (phrase: string) => {
    try {
      let wallet = await WALLET.Import(phrase)
      set((state: any) => ({
        wallets: [...state.wallets, { ...wallet, is_local: true }],
      }))
    } catch (error) {
      console.log(error)
    }
  },
})
