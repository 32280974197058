import { OrderStatus } from '@/interface'
import { popup } from '@/popup_handler'
import { localizeFiat, prettifyAddress } from '@/utils'
import dayjs from 'dayjs'
import { CheckCircle, Loader } from 'lucide-react'

export const AmountComponent = ({ tx }: any) => {
  return (
    <div>
      <div className="flex items-center gap-2 px-4 py-4">
        <div className="h-[1px] w-full bg-[#ADADAD] shrink-1" />
        <p className="text-sm whitespace-nowrap text-gray-500">{dayjs(tx.createdAt).format('DD MMM YYYY, hh:mm a')}</p>
        <div className="h-[1px] w-full bg-[#ADADAD] shrink-1" />
      </div>
      <button
        onClick={() => popup.open('tx_history', tx)}
        className={`flex flex-col p-4 mx-4 shadow-md justify-between rounded-lg text-sm bg-background active:opacity-70 gap-3 pr-12 ${tx.direction ? 'mr-auto' : 'ml-auto'}`}
      >
        <div className="flex  justify-between w-full">
          <p className=" text-lg">
            Payment {tx.direction ? 'from ' : 'to '}
            <div className="inline capitalize">{tx.txUser?.name ? tx.txUser?.name?.split(' ')?.at(0) : prettifyAddress(tx.txUser)}</div>
          </p>
        </div>
        <h2 className={' truncate text-foreground'}>{localizeFiat(tx.amount)}</h2>
        <div className="flex items-center gap-2 w-full text-[12px]">
          {tx.status === OrderStatus.PROCESSING ? <Loader className=" animate-spin-slow " size={18} /> : <CheckCircle color="#30b532" size={18} />} <span className="capitalize">{tx.status}</span>
          <h5>•</h5>
          <span className="">{dayjs(tx.createdAt).format('DD MMM ')}</span>
        </div>
      </button>
    </div>
  )
}
