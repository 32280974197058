import { CHAIN_ID } from '@/constants'

export enum OrderType {
  P2P = 'p2p',
  MERCHANT = 'merchant',
  NONCRAYRECEIVED = 'noncrayreceived',
}

export enum OrderStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface ITx {
  _id: string
  senderUser: IUser | null
  hash: string
  senderAddress: string
  receiverAddress: string
  sourceChain: string
  destinationChain: string
  sourceToken: string
  destinationToken: string
  amount: string
  receiverUser: IUser | null
  status: string
  senderWallet: string | null
  receiverWallet: string | null
  createdAt: Date
  updatedAt: Date
  txUser: IUser | null
  user: string
  read_receipt: boolean
  direction: number
}
export interface IPaymantPayload {
  senderAddress: string
  receiverAddress: string
  sourceChain: number
  destinationChain: number
  amount: string
  orderType: string
}
export interface IChain {
  name: string
  logo: string
  currency: {
    address: string
    name: string
    symbol: string
    decimals: number
    icon: string
  }
  isTestnet?: boolean
  rpc: any
  rpc_url: string
  explorer: string
  isActive?: boolean
  chainId: CHAIN_ID
  enabled?: boolean
}
export enum INetworks {
  TESTNET = 'TESTNET',
  MAINNET = 'MAINNET',
}
export interface IAccount {
  isPrimary?: boolean
  address: string
  userId: string
  _id: string
  name: string
  is_local?: boolean
}
export interface IWallet {
  _id: string
  address: string
  key: string
  userId: string
  name?: string
  is_local?: boolean
  isPrimary?: boolean
  backedup_done?: boolean
}
export interface IToken {
  chainId: CHAIN_ID
  account?: string
  tokenAddress: string
  name: string
  symbol: string
  decimals: number
  icon: string
  amount?: string
  usdBalance?: string
  spend?: string
  isStable?: boolean
  gasless?: boolean
  sourceToken?: string
  sourceChain?: CHAIN_ID
}

export interface IContact {
  _id: string
  resource_name: string
  name: string
  email: string
  phone: string
  exists?: boolean
  dp: string
  address?: string
  username?: string
  user?: string
  userId?: string
}

export interface IUser {
  _id?: string
  username: string
  name: string
  default_chain: CHAIN_ID
  email?: string
  default_currency: {
    name: string
    symbol: string
    conversion_rate: number
  }
}
