import * as React from 'react'

import { cn } from '@/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode
  iconRight?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, icon, autoCapitalize = 'off', iconRight = false, ...props }, ref) => {
  return (
    <div className="relative h-10 w-full text-foreground mt-1">
      <input
        type={type}
        className={cn(
          ` flex w-full rounded-[16px] outline-none text-foreground/90 bg-input-bg/10 placeholder:text-foreground/30 border transition-all duration-150 focus:ring-2  ${
            icon ? (iconRight ? 'pr-10 pl-3' : 'pl-10 pr-3') : 'px-3'
          } py-2 text-lg placeholder:text-left text-left shadow-[0px_-2px_4px_0px_rgba(0,0,0,0.25)_inset]`,
          className
        )}
        ref={ref}
        {...props}
        autoCorrect="off"
        autoCapitalize={autoCapitalize}
      />
      {icon ? <div className={`absolute ${iconRight ? 'right-0 pr-3' : 'left-0 pl-3'} top-0  mt-[3px] h-full flex items-center justify-center `}>{icon}</div> : null}
    </div>
  )
})
Input.displayName = 'Input'

export { Input }
