import { IContact } from '@/interface'
import { get, post } from '@/utils/xhr'

class Contact {
  GetAll = async () => {
    try {
      const contacts = (await get(`/contacts`)).result
      return contacts
    } catch (e) {
      return []
    }
  }
  Add = (contacts: IContact[]) => {
    return post('/contacts', contacts)
  }
}
export const ContactService = new Contact()
