import { ContactService } from '@/api'
import { IContact, IUser } from '@/interface'
import { queryClient } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export const useContactSync = () => {
  const contacts = useQuery(['contacts'], () => ContactService.GetAll())
  const user = queryClient.getQueryData(['user']) as IUser
  useEffect(() => {
    ;(async () => {
      try {
        if (!contacts.data || !NativeBridge.isBridge()) return
        let phoneContacts = (await NativeBridge.Send('GET_CONTACTS')) as any
        phoneContacts = phoneContacts
          .map((contact: any) => ({
            name: contact?.name,
            resource_name: contact?.lookupKey,
            email: contact?.emails?.[0]?.email,
            dp: contact?.imageAvailable ? contact?.image?.base64 : null,
          }))
          .filter((contact: any) => contact.email && contact.name && user.email !== contact.email)
        const oldContacts = contacts.data.map((_: IContact) => _.email)
        const newContacts = phoneContacts.filter((contact: IContact) => !oldContacts.includes(contact.email))
        if (newContacts.length) {
          await ContactService.Add(newContacts)
          await queryClient.invalidateQueries(['contacts'])
        }
      } catch (e) {
        return []
      }
    })()
  }, [contacts.data])
}
