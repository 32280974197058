import { PasskeyService } from '@/api/passkey'
import { startRegistration } from '@simplewebauthn/browser'
import { queryClient } from '.'
import { AuthService } from '@/api'
import { NativeBridge } from './native_bridge'

class Passkey {
  Login = async (_id: string) => {
    try {
      if ((window as any).NATIVE_BRIDGE) {
        let key = (await NativeBridge.Send('GET_NATIVE_PASSWORD', _id)) as string
        let { result } = await AuthService.LoginWithNativePassword(_id, key)
        return result
      } else {
        const { asseResp } = await PasskeyService.AuthOptions(_id)
        // Pass the options to the authenticator and wait for a response
        // const asseResp = await startAuthentication(options)
        let { result } = await AuthService.LoginWithPasskey({ payload: asseResp, _id })
        return result
      }
    } catch (error: any) {
      console.log(error)
      if (error.message === 'User not found' || error.message === 'credentials not found') {
        this._removeUserFromLocalStorage(_id)
      }
      throw error
    }
  }
  Setup = async () => {
    const { _id: userId, email, name, username } = queryClient.getQueryData(['user']) as any

    try {
      if ((window as any).NATIVE_BRIDGE) {
        let key = (await NativeBridge.Send('SETUP_PASSKEY', userId)) as string
        await AuthService.SetupNativePassword(key)
      } else {
        let { result } = await PasskeyService.RegisterOptions()
        const attResp = await startRegistration(result)
        await PasskeyService.RegisterPasskey(attResp)
      }

      localStorage.setItem(`passkey:${userId}`, 'true')
      this._addInLocalStorage({
        email,
        _id: userId,
        name,
        username,
      })
    } catch (error) {
      if (String(error).includes(`Failed to read the 'publicKey'`)) {
        localStorage.setItem(`passkey:${userId}`, 'true')
        let { result } = await PasskeyService.RegisterOptions()
        const email = result.user.name
        this._addInLocalStorage({
          email,
          _id: userId,
        })
      } else {
        throw error
      }
    }
  }
  LoggedUser = () => {
    const users = JSON.parse(localStorage.getItem('loggedUsers') || '[]')
    return users
  }

  _addInLocalStorage = (user: any) => {
    try {
      const users = JSON.parse(localStorage.getItem('loggedUsers') || '[]')
      users.push(user)
      localStorage.setItem('loggedUsers', JSON.stringify(users))
    } catch (error) {
      console.log(error)
      /** if data is corrupted */
      localStorage.setItem('loggedUsers', '[]')
    }
  }

  _removeUserFromLocalStorage = (userId: string) => {
    try {
      let users = JSON.parse(localStorage.getItem('loggedUsers') || '[]')
      users = users.filter((_: any) => _._id !== userId)
      localStorage.setItem('loggedUsers', JSON.stringify(users))
    } catch (error) {
      console.log(error)
      /** if data is corrupted */
      localStorage.setItem('loggedUsers', '[]')
    }
  }
}
export const PasskeyUtil = new Passkey()
