import { useState } from 'react'

export function ToggleButton({ value = false, label, onChange = () => {} }: { value?: boolean; label?: string; onChange?: Function }) {
  const [state, setState] = useState(value)
  if (state !== value) {
    setState(value)
  }
  const handleChange = () => {
    setState(!state)
    onChange(!state)
  }
  return (
    <div className="flex items-center gap-4 cursor-pointer select-none" onClick={handleChange}>
      <div className={`h-9 w-16 flex items-center rounded-full p-[3px]  transition-all duration-150 ${state ? ' bg-primary ' : ' bg-foreground '}`}>
        <div className={` bg-background-secondary/50 w-[28px] h-[28px] rounded-full shadow-2xl transition-all duration-150 ${state ? 'ml-7' : ''}`}></div>
      </div>
      <span>{label}</span>
    </div>
  )
}
