import { Route, Routes } from 'react-router-dom'
import Homepage from './home'
import ImportFromPhrase from './import_account'
import ManageAccounts from './manage_accounts'
import SearchBar from './search'
import SelectContact from './select_contact'
import SendToCPIID from './send_by_cpi_id'
import SendToAddress from './send_by_ens.modal'
import Setting from './settings'
import SetupPasskey from './setup-passkey'
import SetupUsername from './setup-username'
import Transactions from './transactions'
import UpdateAccountInfo from './update_account_info'
import ChatScreen from './send/chat'
import SendMoney from './send'
import DepositScreen from './deposit'
import BalanceScreen from './balance'

const Dashbaord = () => {
  return (
    <Routes>
      <Route index element={<Homepage />} />
      <Route path="/import-account/" element={<ImportFromPhrase />} />
      <Route path="/update-account/:accountId" element={<UpdateAccountInfo />} />
      <Route path="/transactions" element={<Transactions />} />
      <Route path="/setup-passkey" element={<SetupPasskey />} />
      <Route path="/setup-username" element={<SetupUsername />} />
      <Route path="/manage-accounts" element={<ManageAccounts />} />
      <Route path="/settings" element={<Setting />} />
      <Route path="/send-to-cpi" element={<SendToCPIID />} />
      <Route path="/send-to-address" element={<SendToAddress />} />
      <Route path="/select-contact" element={<SelectContact />} />
      <Route path="/deposit-funds" element={<DepositScreen />} />
      <Route path="/search" element={<SearchBar />} />
      <Route path="/chat/*" element={<ChatScreen />} />
      <Route path="/send-money/*" element={<SendMoney />} />
      <Route path="/balance" element={<BalanceScreen />} />
    </Routes>
  )
}
export default Dashbaord
