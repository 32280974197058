import { useEffect } from 'react'

const NativeAuthRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const cb = urlParams.get('cb')
    if (cb) {
      ;(window as any).location = cb + window.location.hash
    }
  }, [])
  return <p className="p-1">Please wait....</p>
}

export default NativeAuthRedirect
