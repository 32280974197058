import { ChatService, ContactService, HistoryService, PaymentService } from '@/api'
import UserProfile from '@/assets/icons/user-profile.svg'
import { Button } from '@/cn/ui/button'
import { REFETCH_INTERVAL } from '@/constants'
import { useContactSync } from '@/hooks'
import { IContact, ITx, IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { useAppStore } from '@/store'
import { SHOW, handleInvite, handleQrScan, localizeFiat, queryClient, sortByTime } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { CopyButton, DP } from '@/ui'
import { ChevronRight, Contact, Download, Plus, QrCode, Search, Send, Wallet } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ShowMoreList from './components/show_more_list'
import { CreateWalletScreen } from './create_wallet'
import { balanceService } from '@/api/balance'
import HistoryIcon from '@/assets/icons/history.svg'
import BankAccountIcon from '@/assets/icons/bank_account.svg'

const MODULES = [
  {
    name: 'Scan Any QR code',
    icon: <QrCode />,
    onClick: handleQrScan,
  },
  {
    name: 'Pay Contact',
    icon: <Contact />,
    to: '/dashboard/search/',
  },
  {
    name: 'Pay CPI-ID',
    icon: <Send />,
    // onClick: () => popup.open("send_by_cpi_id"),
    to: '/dashboard/send-to-cpi/',
  },
  {
    name: 'Pay Address',
    icon: <Wallet />,
    // onClick: () => popup.open("send_by_ens"),
    to: '/dashboard/send-to-address/',
  },
  {
    name: 'Deposit',
    icon: <Plus />,
    to: '/dashboard/deposit-funds/',
  },
  {
    name: 'Receive',
    icon: <Download />,
    onClick: () => popup.open('deposit_from_eoa'),
  },
]
const Homepage = () => {
  const { wallets, is_loading, wallet, network } = useAppStore((state) => state) as any
  const user = queryClient.getQueryData(['user']) as IUser
  const balance = useQuery(['balance', wallet?._id, network], () => balanceService.Get({ address: wallet?.address }), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const chainBalance = useMemo(() => {
    if (!balance.data) return
    return balance.data?.result.reduce((a: any, b: any) => a + parseFloat(b.usdBalance), 0)
  }, [balance.data?.result, user.default_chain])
  const contacts = useQuery(['contacts'], () => ContactService.GetAll())

  const transactions = useQuery(['transactions'], () => HistoryService.Get(), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const navigation = useNavigate()
  const chatMessages = useQuery(['chat'], () => ChatService.GetAll(), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const recent = useMemo(() => {
    if (!transactions.data || !contacts.data || !chatMessages.data || !wallet) return []
    const recentUsers = transactions.data.map((tx: ITx) => {
      const IS_SENT_TX = tx.senderUser?._id === user._id
      if (IS_SENT_TX) {
        return {
          name: tx.receiverUser?.name,
          address: tx.receiverAddress,
          username: tx.receiverUser?.username,
          userId: tx.receiverUser?._id,
          createdAt: tx.createdAt,
        }
      } else {
        return {
          name: tx.senderUser?.name,
          address: tx.senderAddress,
          username: tx.senderUser?.username,
          userId: tx.senderUser?._id,
          createdAt: tx.createdAt,
        }
      }
    })
    const contactsData = contacts.data
      .filter((_: IContact) => _.address)
      .map((contact: IContact) => ({
        name: contact.name,
        address: contact.address,
        username: contact.username,
        userId: contact.userId,
      }))
    const contactUserIds = contactsData.map((_: any) => _.userId)
    const unreadChatUserIds = chatMessages.data.filter((_: any) => _.read_receipt === 'pending').map((_: any) => _.senderUser)
    const unreadTxUsrIds = transactions.data.filter((_: any) => _.senderUser?._id !== user._id && _.read_receipt === 'pending').map((_: any) => _.senderUser?._id)
    ;[...recentUsers, ...contactsData].map((user) => {
      if ([...unreadChatUserIds, ...unreadTxUsrIds].includes(user.userId)) {
        user.read_receipt = 'pending'
      }
    })
    const chatByUnknownUsers = chatMessages.data.filter((_: any) => !contactUserIds.includes(_.senderUser))
    const chat = chatByUnknownUsers.map((chat: any) => {
      const IS_SENT_MESSAGE = chat?.senderUser?._id === user?._id
      if (IS_SENT_MESSAGE) {
        return {
          name: chat.receiverUser?.name,
          username: chat.receiverUser?.username,
          createdAt: chat.createdAt,
        }
      } else {
        return {
          name: chat.senderUser?.name,
          username: chat.senderUser?.username,
          read_receipt: chat.read_receipt,
          createdAt: chat.createdAt,
        }
      }
    })
    const data = [...new Map([...chat, ...recentUsers].sort(sortByTime).map((item: any) => [item.username, item])).values()].filter((_) => _.address !== wallet?.address)

    return data.filter((_) => _.name)
  }, [transactions.data, contacts.data, chatMessages.data, wallet])
  const contactsList = useMemo(() => {
    if (!contacts.data) return []

    const contactsData = contacts.data
      .filter((_: IContact) => _.address)
      .map((contact: IContact) => ({
        name: contact.name,
        address: contact.address,
        username: contact.username,
        userId: contact.userId,
      }))
    return contactsData.filter((_: any) => _.address !== wallet?.address)
  }, [contacts.data, wallet?.address])
  useContactSync()
  useEffect(() => {
    if (!wallet) return
    const isSkipBackup = localStorage.getItem(`skip_backup:${wallet._id}`)
    if (!wallet?.backedup_done && transactions.data?.length && wallet?.is_local && isSkipBackup !== 'true') {
      popup.open('backup_reminder')
    } else if (wallet?.backedup_done && !wallet?.is_local && !wallet.key && isSkipBackup !== 'true') {
      popup.open('recovery_reminder')
    } else if (!wallet?.is_local && !wallet.version && wallet.key) {
      popup.open('migrate_wallet')
    }
  }, [wallet, transactions.data])

  const texts = ['Pay to contact', 'Pay anyone on CPI', 'Pay to address or ENS']

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length)
    }, 3000)

    return () => clearInterval(interval) // Cleanup the interval on component unmount
  }, [texts.length])

  const handleSend = async (user: any) => {
    if (user.address) {
      navigation('/dashboard/chat', { state: user })
    } else {
      const userData = await PaymentService.GetUserAddressByCPID(user.username)
      navigation('/dashboard/chat', {
        state: {
          address: userData.result.address,
          username: userData.result.cpiid,
          name: userData.result.user_name,
          userId: userData.result.userId,
        },
      })
    }
  }
  const MAX_TILES = 8
  if (wallets !== null && !wallets.length && !is_loading) {
    return <CreateWalletScreen />
  }

  return (
    <div className={`relative flex flex-col h-full`}>
      <div className="flex flex-col overflow-scroll pb-8 h-full">
        <div className="flex gap-4 items-center absolute top-0 w-full px-4 mt-4 z-10">
          <Link to="/dashboard/search" className="flex-grow flex  items-center gap-2 px-4 py-[10px] rounded-[12px] font-medium leading-tight text-primary bg-[#FFFAE5]/70">
            <Search size={18} />
            {texts[currentIndex]}
          </Link>
          <Link to="/dashboard/settings/" className="h-[52px] w-[52px] shrink-0 rounded-full flex items-center justify-center text-primary active:opacity-90">
            <DP name={user.name} size="36px" className=" text-[18px]" />
          </Link>
        </div>
        <div className="">
          <img src="/illustration.png" className="  z-[-1] w-full h-[236px] object-cover" />
          <div className="bg-[#FDCFA1] flex justify-between py-1 px-4">
            <p>Your Balance</p>
            <span className="text-[#084887]">{chainBalance ? localizeFiat(chainBalance) : '-'}</span>
          </div>
        </div>
        <div className="flex flex-col  w-full px-4 gap-4 flex-grow">
          <div className="grid grid-cols-4">
            {MODULES.map((module) =>
              module.onClick ? (
                <button
                  key={module.name}
                  onClick={() => module.onClick({ navigation })}
                  className="flex flex-col items-center justify-start w-20 gap-2 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                >
                  <div className="h-8 w-8 flex items-center justify-center">{module.icon}</div>
                  <span className="inline leading-4 text-center text-foreground text-sm font-medium">{module.name}</span>
                </button>
              ) : (
                <Link
                  key={module.name}
                  to={module.to}
                  className="flex flex-col items-center justify-start w-20 gap-2 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                >
                  <div className="h-8 w-8  flex items-center justify-center">{module.icon}</div>
                  <span className="inline leading-4 text-center text-foreground text-sm font-medium">{module.name}</span>
                </Link>
              )
            )}
          </div>
          <div className="mx-auto px-5 py-[6px] border-black border rounded-[16px] bg-[#FCF4EF] flex gap-2">
            CPI ID: {user.username}@cray <CopyButton value={`${user.username}@cray`} iconClass="text-[#424242]" />
          </div>
          <div className="flex flex-col gap-4">
            <div {...SHOW(!!recent.length)} className="flex flex-col">
              <p className="text-foreground text-base font-medium leading-tight">Recent</p>
              <ShowMoreList
                items={recent}
                maxItems={MAX_TILES}
                renderItem={(user) => (
                  <button
                    key={user.username}
                    onClick={() => handleSend(user)}
                    className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                  >
                    <div {...SHOW(user.read_receipt === 'pending')} className="absolute bg-primary w-4 h-4 rounded-full top-1 right-1"></div>
                    {user.name ? (
                      <DP name={user.name} size="52px" className="text-[32px] border border-[#444444]" />
                    ) : (
                      <img className="h-12 w-12 rounded-full object-cover border border-[#444444]" src={UserProfile} />
                    )}
                    <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">{user.name || 'Unknown'}</span>
                  </button>
                )}
              />
            </div>
            <div {...SHOW(!!contactsList.length)} className="flex flex-col">
              <p className="text-foreground text-base font-medium leading-tight">People</p>
              <ShowMoreList
                items={contactsList}
                maxItems={MAX_TILES}
                renderItem={(user) => (
                  <button
                    key={user.username}
                    onClick={() => handleSend(user)}
                    className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
                  >
                    <div {...SHOW(user.read_receipt === 'pending')} className="absolute bg-primary w-4 h-4 rounded-full top-1 right-1"></div>
                    {user.name ? (
                      <DP name={user.name} size="52px" className="text-[32px] border border-[#444444]" />
                    ) : (
                      <img className="h-12 w-12 rounded-full object-cover border border-[#444444]" src={UserProfile} />
                    )}
                    <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">{user.name || 'Unknown'}</span>
                  </button>
                )}
              />
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col">
                <Link to="transactions" className="flex gap-4 items-center">
                  <div className="flex items-center flex-grow gap-4">
                    <img src={HistoryIcon} />
                    <span className="text-black">Show transaction history</span>
                  </div>
                  <ChevronRight />
                </Link>
              </div>
              <div className="flex flex-col">
                <Link to="balance" className="flex gap-4 items-center">
                  <div className="flex items-center flex-grow gap-4">
                    <img src={BankAccountIcon} />
                    <span className="text-black">Check account balance</span>
                  </div>
                  <ChevronRight />
                </Link>
              </div>
              <div className="flex flex-col gap-4 ">
                <p className="font-medium leading-tight">Can't find a friend? Invite & unlock bonuses!</p>
                <Button onClick={handleInvite} className="w-48">
                  Share Invite
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wallet && !wallet?.is_local ? <div className="bg-red-500 text-white top-0  w-full left-0 text-center font-bold py-2 z-10">This account is not availabel</div> : null}
    </div>
  )
}

export default Homepage
