import ENV from '@/config'
import axios, { AxiosInstance } from 'axios'
let controller = new AbortController()

export const instance: AxiosInstance = axios.create({
  signal: controller.signal,
  baseURL: ENV.HOST,
  // timeout: 10000,
  headers: {
    session_key: (window as any).session_key,
    network: window.localStorage.getItem('network') || 'MAINNET',
  },
  withCredentials: true,
})
;(window as any).abortRequest = () => {
  controller.abort()
  controller = new AbortController()
  instance.defaults.signal = controller.signal
}
export function get(url: string, params?: any) {
  return instance
    .get(url, params)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response && response.data ? response.data : response))
}

export function post(url: string, payload?: any) {
  return instance
    .post(url, payload)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response && response.data ? response.data : response))
}

export function put(url: string, payload?: any, options?: any) {
  return instance
    .put(url, payload, options)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response && response.data ? response.data : response))
}

export function deleteRequest(url: string, options?: any) {
  return instance
    .delete(url, options)
    .then(({ data }) => data)
    .catch(({ response }) => Promise.reject(response && response.data ? response.data : response))
}
instance.interceptors.response.use(
  function (response) {
    response.data = {
      result: response.data,
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
