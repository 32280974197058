import { useEffect, forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import PopupOverlay from '../popup_overlay'
import { ArrowLeft } from 'lucide-react'
import { Button } from '@/cn/ui/button'

export const SideSheet = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      onClickOutside,
      className = '',
    }: {
      props?: any
      page?: Boolean
      children?: ReactNode | ReactNode[]
      close?: Function
      onClickOutside?: Function
      className?: string
    },
    ref: any
  ) => {
    const closeModal = (e: any) => {
      if ((ref?.current && ref?.current?.children[1].contains(e.target)) || !document.body?.contains(e.target)) {
        return
      }

      if (onClickOutside) {
        onClickOutside()
      }
    }
    useEffect(() => {
      setTimeout(() => {
        document.body.addEventListener('click', closeModal)
      }, 0)
      return function cleanup() {
        document.body.removeEventListener('click', closeModal)
      }
    }, [])
    return (
      <>
        <div ref={ref} className="sidesheet-component ">
          <PopupOverlay />
          <div ref={ref} className={twMerge(`  fixed top-0 w-full shadow-lg h-full overflow-auto bg-white ease-in-out z-[20] ${true ? 'right-0' : '-right-full'}`, className)}>
            {children}
          </div>
        </div>
      </>
    )
  }
)
export const SidesheetMenu = ({ title, onBack }: { title: string; onBack: Function }) => {
  return (
    <div className="flex items-center gap-2">
      <Button variant="ghost" className="">
        <ArrowLeft onClick={() => onBack()} />
      </Button>
      <span className="text-lg font-semibold">{title}</span>
    </div>
  )
}
interface Props {
  props?: any
  page?: Boolean
  children?: ReactNode
  close?: Function
  onClickOutside?: Function
  className?: string
}
