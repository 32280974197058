import { Button } from '@/cn/ui/button'
import { useToast } from '@/cn/ui/use-toast'
import { IUser } from '@/interface'
import { Loading } from '@/ui'
import { queryClient } from '@/utils'
import { PasskeyUtil } from '@/utils/passkey'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SetupPasskey = () => {
  const user = queryClient.getQueryData(['user']) as IUser
  const [passkeySet, setPasskeySet] = useState(false)
  const { toast } = useToast()
  const navigator = useNavigate()
  const setupPasskey = useMutation(() => PasskeyUtil.Setup(), {
    onSuccess: () => {
      setPasskeySet(true)
      setTimeout(() => {
        navigator('/dashboard/')
      }, 1000)
    },
    onError: (error: any) => {
      let title = error.code === 'ERR_AUTHENTICATION' ? 'Authentication Failed' : 'Something went wrong'
      toast({
        variant: 'destructive',
        title,
      })
    },
  })
  if (passkeySet) {
    return (
      <div className="h-full bg-bakground text-foreground flex items-center justify-center gap-4 flex-col">
        <h1>🎉</h1>
        <h2 className=" text-center">All Set, {user.name}!</h2>
        <Loading className="fill-primary" />
      </div>
    )
  }
  return (
    <div className="flex flex-col items-center justify-center h-full gap-4 px-4 rounded-b-md bg-background text-foreground">
      <h3 className=" leading-zeros text-center">Now let's setup a passkey for your account</h3>
      <p className="text-center text-sm mb-16 px-3">We are storing a secret in your device’s secure store. This secret will only be accessed by the passkey, you will use to authorize payments.</p>
      <div className="w-full">
        <Button className="w-full" onClick={() => setupPasskey.mutate()} loading={setupPasskey.isLoading}>
          Setup Passkey
        </Button>
        <span className="text-[10px] px-4 text-[#030303]">Note: This will need access to your device face ID</span>
      </div>
    </div>
  )
}
export default SetupPasskey
