import { useEffect } from 'react'
import { Button } from './button'

const ErrorBoundaryComponent = ({ error, resetErrorBoundary }: any) => {
  useEffect(() => {
    ;(window as any).posthog.capture('APP_ERROR', { error: JSON.stringify(error, Object.getOwnPropertyNames(error)) })
  }, [])
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-8">
      <div className="flex flex-col justify-center items-center gap-2 font-extrabold">
        <div className="text-3xl ">Whoops!</div>
        <div className="font-bold text-gray-500 ">Something went wrong</div>
      </div>
      <div className="flex flex-col items-center gap-4">
        <Button onClick={resetErrorBoundary} className="btn btn--primary w-48">
          Refresh
        </Button>
        <Button type="secondary" onClick={() => ((window as any).location = window.location.origin)} className=" w-48 underline text-gray-500 hover:text-gray-700 ">
          Back
        </Button>
      </div>
    </div>
  )
}
export default ErrorBoundaryComponent
