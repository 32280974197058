const ENV = {
  HOST: `http://${window.location.hostname}:8080`,
  WALLET_HOST: 'wallet.localhost:5173',
}

{
  /** override config by .env */
  const DOT_ENV = Object.keys(import.meta.env)
    .filter((_) => _.indexOf('VITE_') === 0)
    .reduce((obj: { [key: string]: string }, key) => {
      obj[key.replace('VITE_', '')] = import.meta.env[key]
      return obj
    }, {})
  Object.assign(ENV, DOT_ENV)
}
export default ENV
