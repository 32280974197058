import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { Loading } from '@/ui'
import { cn } from '@/utils'

const buttonVariants = cva(
  ' shrink-0 inline-flex items-center justify-center whitespace-nowrap rounded-[15px]  ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-[#444444] shadow-[0px_-2px_4px_0px_rgba(0,0,0,0.25)_inset] noto-sans-sundanese',
  {
    variants: {
      variant: {
        default: 'bg-primary text-black md:hover:bg-primary/70 active:opacity-70 ',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/70 active:opacity-70',
        ghost: 'hover:bg-wowza-200 hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-[44px] px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, loading, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button'
  return (
    <>
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} disabled={loading || props.disabled}>
        {loading ? <Loading className="fill-gray-800" /> : props.children}
      </Comp>
    </>
  )
})
Button.displayName = 'Button'

export { Button, buttonVariants }
