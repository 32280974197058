import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useAppStore } from '@/store'
import { Label } from '@/cn/ui/label'
import { Link, useNavigate } from 'react-router-dom'

const ImportFromPhrase = () => {
  const navigate = useNavigate()
  const { importWallet } = useAppStore((state) => state) as any
  const walletMutation = useMutation((address: string) => importWallet(address), {
    onSuccess: () => {
      navigate('../')
    },
  })
  const [address, setAddress] = useState('')
  return (
    <div className=" flex flex-col px-4 h-full gap-6 pt-24 pb-4">
      <div className="flex flex-col gap-4 pb-4 ">
        <h2>Import via private key</h2>
        <p>Import an account via private key, found in your wallet provider.</p>
      </div>
      <div className="flex-grow">
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="privatekey">Privatekey</Label>
          <Input onChange={(e) => setAddress(e.target.value)} value={address} placeholder="privatekey" />
        </div>
      </div>
      <div className="flex items-center flex-col gap-2">
        <Button className="w-full" loading={walletMutation.isLoading} onClick={() => walletMutation.mutate(address)}>
          Import
        </Button>
        <Link to="../">Back</Link>
      </div>
    </div>
  )
}
export default ImportFromPhrase
