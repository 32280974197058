import { _sleep } from '.'

export let ACTIVE_CURRENCY = {
  currency: 'USD',
  conversion_rate: 1,
  symbol: '$',
}
export const CURRENCY = [
  {
    name: 'AED',
    symbol: 'AED',
    display: 'United Arab Emirates Dirham',
  },
  {
    name: 'USD',
    symbol: '$',
    display: 'United States Dollar',
  },
  {
    name: 'INR',
    symbol: '₹',
    display: 'Indian Rupee',
  },
]
export const currencyMap = CURRENCY.reduce((obj: any, prop: any) => {
  obj[prop.name] = prop
  return obj
}, {}) as any
export function localizeFiat(number: number | string) {
  if (typeof number === 'string') {
    number = Number(number)
  }
  return ACTIVE_CURRENCY.symbol + ' ' + (ACTIVE_CURRENCY.conversion_rate * number).toFixed(2)
}
const MILLION = 10 ** 6
export default function AmountToWord(amount: string | number) {
  if (typeof amount === 'string') {
    amount = Number(amount)
  }
  if (amount >= MILLION) {
    return localizeFiat(amount / MILLION) + 'M'
  }
  return localizeFiat(amount)
}
