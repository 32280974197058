export class GoogleContacts {
  constructor() {}
  Authenticate = async () => {
    return new Promise((resolve: any, reject: any) => {
      const google = (window as any).google
      const client = google.accounts.oauth2.initTokenClient({
        client_id: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/contacts.readonly',
        callback: async (res: any) => {
          ;(window as any).gapi.client.setToken({
            access_token: res.access_token,
          })
          resolve()
        },
        error_callback: reject,
      })
      client.requestAccessToken()
    })
  }
  PullContact = async (pageToken?: string) => {
    const { result } = await (window as any).gapi.client.people.people.connections.list({
      resourceName: 'people/me',
      personFields: 'names,emailAddresses,phoneNumbers,coverPhotos,photos',
      pageToken,
    })
    let contacts = (result.connections || [])
      .map((data: any) => ({
        resource_name: data.resourceName,
        name: data?.names?.[0].displayName,
        phone: data?.phoneNumbers?.[0]?.canonicalForm,
        email: data?.emailAddresses?.[0]?.value,
        dp: data?.photos?.[0]?.url,
      }))
      .filter((_: any) => _.email)
    if (result.nextPageToken) {
      let nextPage = await this.PullContact(result.nextPageToken)
      contacts.push(...nextPage)
    }
    return contacts
  }
}
