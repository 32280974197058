import { INetworks } from '@/interface'
//@ts-ignore
export const UserSlice = (set: any, ...params: any) => ({
  network: localStorage.getItem('network') || INetworks.MAINNET,
  selectedAccount: '',
  setAccount: async (address: string) => {
    try {
      set(() => ({
        selectedAccount: address,
      }))
    } catch (error) {
      console.log(error)
    }
  },
  changeNetwork: (network: string) => {
    try {
      localStorage.setItem('network', network)
      set(() => ({
        network,
      }))
    } catch (error) {
      console.log(error)
    }
  },
  changeChain: async (chain: number) => {
    try {
      localStorage.setItem('chain', chain.toString())
      set(() => ({
        chain,
      }))
    } catch (error) {
      console.log(error)
    }
  },
})
