import { ChatService, HistoryService } from '@/api'
import { Button } from '@/cn/ui/button'
import { REFETCH_INTERVAL } from '@/constants'
import { IUser } from '@/interface'
import { popup } from '@/popup_handler'
import { DP, Modal } from '@/ui'
import SuspenceLoader from '@/ui/suspence_loader'
import { SHOW, prettifyAddress, queryClient } from '@/utils'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ArrowLeft, DollarSign } from 'lucide-react'
import { useMemo } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AmountComponent } from './amount_component'
import { MEssageComponent } from './message_component'
const getMessageData = (receiverUser: any, senderUser: any) => {
  return {
    _id: Math.random(),
    senderUser,
    receiverUser,
    read_receipt: 'pending',
    message: 'Hi 👋',
    createdAt: new Date(),
    updatedAt: new Date(),
  }
}
const ChatScreen = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleBack = () => navigate('/dashboard')
  const user = queryClient.getQueryData(['user']) as IUser
  const userId = user?._id

  const props = location.state
  const transactions = useQuery(
    ['transactions', props?.address],
    () =>
      HistoryService.Get({
        address: props?.address,
      }),
    {
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const handleSendHi = () => {
    queryClient.setQueriesData(['chat', props.userId], (data: any) => {
      const receiverUser = {
        _id: props.userId,
        name: props.name,
      }
      const senderUser = {
        _id: user._id,
        name: user.name,
      }
      return [getMessageData(receiverUser, senderUser), ...data]
    })
    return ChatService.Send({
      receiverUser: props.userId,
      message: 'Hi 👋',
    })
  }
  const sendHiMutation = useMutation(() => handleSendHi(), {
    onSuccess: () => {},
    onError: async () => {
      await queryClient.invalidateQueries(['chat', props.userId])
    },
  })
  const chatMessages = useQuery(['chat', props?.userId], () => ChatService.GetByUser(props?.userId), {
    enabled: !!props?.userId,
    refetchInterval: REFETCH_INTERVAL,
  })

  const screenData = useMemo(() => {
    if (!transactions.data || (!chatMessages.data && props?.userId)) {
      return []
    }
    let tx = JSON.parse(JSON.stringify(transactions.data))
    tx.forEach((tx: any) => {
      tx.type = 'tx'
      tx.amount = Number(tx.amount)
    })
    chatMessages.data?.forEach((message: any) => {
      message.type = 'message'
    })
    const ungroupedTx = [...[...tx, ...(chatMessages.data || [])].sort((a, b) => new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf())].reverse()
    return ungroupedTx
  }, [transactions.data, chatMessages.data])
  if (!props) {
    return <Navigate to="/dashboard/" />
  }

  return (
    <Modal title="Select contact" navClassName="px-4" className={`flex flex-col justify-between h-full transition-all text-foreground duration-150 p-0 gap-0`}>
      <div className="p-4 flex items-center gap-2 border-b border-background bg-gray-100">
        <button onClick={handleBack} className="flex items-center justify-center cursor-pointer hover:bg-background rounded-full p-2 active:opacity-70">
          <ArrowLeft />
        </button>
        <div className="flex gap-4 items-center ">
          {props.dp ? <DP name={props.name} /> : null}
          <div className="flex flex-col">
            <p className="font-bold" {...SHOW(!!props.name)}>
              {props.name}
            </p>
            <span className="text-sm" {...SHOW(!!props.address)}>
              {props.username ? props.username + '@cray' : prettifyAddress(props.address)}
            </span>
          </div>
        </div>
      </div>

      <div className="test w-full h-full flex-col-reverse  flex gap-2 overflow-scroll pb-24 pt-8">
        {transactions.isLoading || (chatMessages.isLoading && props.userId) ? (
          <SuspenceLoader {...SHOW()} />
        ) : screenData.length ? (
          screenData.map((data: any) => {
            return data.type === 'tx' ? <AmountComponent key={data._id} tx={data} userId={userId} /> : <MEssageComponent key={data._id} data={data} userId={userId} />
          })
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            <button onClick={() => sendHiMutation.mutate()} className="text-primary font-medium active:bg-primary/20 px-3 py-1 rounded-md">
              Say "Hi! 👋"
            </button>
          </div>
        )}
      </div>
      <div>
        <div className="fixed bottom-8 left-4  flex gap-4 ">
          <button onClick={() => sendHiMutation.mutate()} className="flex items-center gap-[6px] rounded-3xl px-4 py-3 text-primary bg-[#FFF9E7]">
            <img src="/icons/waving_hand.svg" className="w-6" /> Wave
          </button>
          <button onClick={() => popup.open('coming_soon')} className="flex items-center gap-[6px] rounded-3xl px-4 py-3 text-primary bg-[#FFF9E7]">
            <DollarSign /> Request
          </button>
        </div>
        <div className="fixed bottom-8 right-4  flex gap-4 ">
          <Button
            onClick={() =>
              navigate('../send-money', {
                state: props,
              })
            }
          >
            Pay
          </Button>
        </div>
      </div>
    </Modal>
  )
}
export default ChatScreen
