import { generateColour } from '@/utils'
import { twJoin } from 'tailwind-merge'

export const DP = ({ name = '', size = '32px', className }: { name: string; size?: string; className?: string }) => {
  return (
    <div
      className={twJoin('rounded-full capitalize flex items-center justify-center text-background aspect-square shrink-0 shadow-[0px_1px_2px_0px_rgba(0,0,0,0.25)]   font-medium', className)}
      style={{ width: size, height: size, backgroundColor: generateColour(name) }}
    >
      {name[0]}
    </div>
  )
}
