import { Link } from 'react-router-dom'
import { ArrowLeft, Pencil } from 'lucide-react'
import { CopyButton } from '@/ui'
import { HIDE, SHOW, localizeFiat, prettifyAddress } from '@/utils'
import { useAppStore } from '@/store'
import { IWallet } from '@/interface'
import { balanceService } from '@/api/balance'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const ManageAccounts = () => {
  const { wallets, is_loading } = useAppStore((state) => state) as any
  const walletIds = wallets.map((_: IWallet) => _.address)
  const balance = useQuery(['balance', walletIds], () => balanceService.Get({ address: walletIds }), {
    enabled: !is_loading,
  })
  const balanceObject = useMemo(() => {
    if (!balance.data) return {}
    return balance.data.result.reduce((obj: any, prop: any) => {
      if (!obj[prop.account]) {
        obj[prop.account] = 0
      }
      obj[prop.account] += parseFloat(prop.usdBalance)
      return obj
    }, {})
  }, [balance.data])
  return (
    <div className="h-full flex flex-col bg-background text-foreground  ">
      <div className="flex items-center gap-2">
        <Link to="/dashboard/" className="p-[10px]">
          <ArrowLeft />
        </Link>
        <p className="text-lg font-bold">Manage Account</p>
      </div>
      <div className="flex flex-col gap-2 p-4 flex-grow  overflow-scroll">
        {[...wallets, ...wallets].map((wallet: IWallet) => {
          return (
            <div className="flex flex-col gap-2 shadow-[0px_20px_30px_0px_rgba(0,0,0,0.05)] p-4 bg-background-secondary rounded-[8px]">
              <div className="flex justify-between">
                <span className=" truncate capitalize">{wallet.name}</span>
                <div className="flex items-center gap-4">
                  <div className={`flex gap-2 items-center inline-code font-bold py-1 px-2 rounded-[6px] bg-background`}>
                    {prettifyAddress(wallet?.address)} <CopyButton value={wallet?.address} iconClass="text-slate-600" />
                  </div>
                  <Link to={`/dashboard/update-account/${wallet._id}`} className="flex items-center justify-center shrink-0 w-8 h-8 rounded-full hover:bg-background">
                    <Pencil width={16} />
                  </Link>
                </div>
              </div>
              <div {...SHOW(balance.isLoading)} className="h-9 w-16 rounded-sm bg-background animate-pulse"></div>
              <h2 {...HIDE(balance.isLoading)}>{localizeFiat(balanceObject[wallet.address] || 0)}</h2>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default ManageAccounts
