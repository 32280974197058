import { useLocation } from 'react-router-dom'
import AppRouter from './app/index'
import PopupHandler, { popup } from './popup_handler'
import { useEffect } from 'react'
import { platformAuthenticatorIsAvailable } from '@simplewebauthn/browser'
import { NativeBridge } from './utils/native_bridge'
import { getVersion } from './utils'
let MIN_IOS_VERSION_ALLOWED = '0.2.1'
let MIN_ANDROID_VERSION_ALLOWED = '0.2.1'
let MIN_ALLOWED_VERSION = getVersion((window as any).IS_IOS ? MIN_IOS_VERSION_ALLOWED : MIN_ANDROID_VERSION_ALLOWED)

function App() {
  useEffect(() => {
    ;(async () => {
      const CURRENT_VERSION = getVersion((window as any).NATIVE_APP_VERSION || '')
      if (NativeBridge.isBridge()) {
        if ((window as any).CAN_USE_BIOMETRIC_AUTHENTICATION === false) {
          return popup.open('not_supported')
        }
        if (CURRENT_VERSION < MIN_ALLOWED_VERSION) {
          return popup.open('update_required_error')
        }
      } else {
        if (!(await platformAuthenticatorIsAvailable())) {
          popup.open('not_supported')
        }
      }
    })()
  }, [])
  let location = useLocation()
  ;(window as any).location_key = location.key
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div id="app" className="  relative overflow-hidden w-full md:w-[384px] mx-auto h-screen md:h-[667px] shadow">
        <AppRouter />
        <PopupHandler />
      </div>
    </div>
  )
}

export default App
