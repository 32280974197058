import { NETWORKS_BY_CHAINID, tokenAddressMap } from '@/constants'
import { IToken } from '@/interface'
import { ACTIVE_CURRENCY, HIDE, localizeFiat } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'

const PaymentBreakdown = ({ tokenBreakdown, amount, description = '', children }: any) => {
  const handleOpenExplorerLink = ({ sourceChain, chainId, hash }: any) => {
    if (!hash) return
    const url = (NETWORKS_BY_CHAINID as any)[sourceChain || chainId].explorer + '/tx/' + hash
    if (NativeBridge.isBridge()) {
      NativeBridge.Send('OPEN_EXPLORER_LINK', { url })
    } else {
      window.open(url)
    }
  }
  return (
    <div className={`py-6 flex flex-col gap-4 bg-background w-full wowza-shadow rounded-t-lg px-6`}>
      <span {...HIDE(tokenBreakdown.length === 1)} className=" text-xs">
        {description}
      </span>
      <div {...HIDE(tokenBreakdown.length === 1)} className=" rounded-[16px] border border-[#C8C8C8] flex flex-col">
        <h5 className="text-center">Breakdown</h5>
        <div className="flex justify-between px-4">
          <div className="flex gap-2 overflow-scroll">
            {tokenBreakdown.map((token: IToken, i: number) => (
              <div onClick={() => handleOpenExplorerLink(token)} key={token.tokenAddress} className="flex gap-2 items-center shrink-0">
                <div className="flex flex-col items-center">
                  <div className="relative">
                    <img className="absolute -top-1 -right-1 z-10  h-5 p-[2px] bg-white border-gray-300 border-2 rounded-full" src={NETWORKS_BY_CHAINID[token.sourceChain || token.chainId].logo} />
                    <img className="h-8 w-8 p-[2px]  border-gray-300 border-2 rounded-full" src={tokenAddressMap[token.sourceToken || token.tokenAddress].icon} />
                  </div>
                  <p className="text-[9px] whitespace-nowrap">{localizeFiat(Number(token.spend || token.amount) / 10 ** tokenAddressMap[token.sourceToken || token.tokenAddress].decimals)}</p>
                </div>
                <h4 className=" shrink-0">{i === tokenBreakdown.length - 1 ? '' : '+'}</h4>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center">
            <h4 className=" whitespace-nowrap">= {localizeFiat(amount)}</h4>
            <h6>{ACTIVE_CURRENCY.currency !== 'USD' ? `$ ${Number(amount).toFixed(4)}` : ''}</h6>
          </div>
        </div>
      </div>
      {children}
    </div>
  )
}
export default PaymentBreakdown
