import { PaymentService } from '@/api'
import { WalletService } from '@/api/wallet'
import { CHAIN_ID, CRAY_TRANSFER_ADDRESS, NETWORKS_BY_CHAINID, SIGN_TYPE } from '@/constants'
import erc20ABI from '@/constants/erc20.abi.json'
import { INetworks, IWallet, OrderType } from '@/interface'
import { useAppStore } from '@/store'
import { Wallet, ethers } from 'ethers'
import { combine } from 'shamir-secret-sharing'
import { create } from 'web3-eth-accounts'
import { arrayBuffertoString, hexStringToArrayBuffer, queryClient, sha256 } from '.'
import { CRYPTO } from './crypto'
import { NativeBridge } from './native_bridge'
const deadline = () => Math.floor(Date.now() / 1000) + 4200
interface IDomainData {
  domainData: { chainId: CHAIN_ID; verifyingContract: string; version: string; name: string; nonce: number }
}
interface IBreakdown {
  chainId: CHAIN_ID
  account: string
  tokenAddress: string
  name: string
  symbol: string
  decimals: number
  icon: string
  balance: string
  amount: string
  usdBalance: string
  spend: string
}
export class WALLET {
  static Get = (walletId: string) => {
    const wallets = WALLET.GetAll()
    return wallets[walletId]
  }
  static GetAll = () => JSON.parse(window.localStorage.getItem('wallets') || '{}') as any

  static _reset_local_wallet = () => {
    window.localStorage.setItem('wallets', '{}')
  }
  static _push = async ({ _id, key, address }: { _id: string; key: string; address: string }) => {
    try {
      const { _id: userId } = queryClient.getQueryData(['user']) as any

      const wallets: any = WALLET.GetAll()
      wallets[_id] = { key, address, _id, userId }
      window.localStorage.setItem('wallets', JSON.stringify(wallets))
    } catch (error) {
      console.log(error)
      WALLET._reset_local_wallet()
    }
  }
  static Import = async (privateKey: string) => {
    try {
      const passPhrase = CRYPTO.GeneratePassphrase()
      const provider = new Wallet(privateKey)
      const key = CRYPTO.EncryptData(privateKey, passPhrase)
      const address = await provider.getAddress()
      let {
        result: { _id },
      } = await WalletService.Create({
        pass_phrase: passPhrase,
        address,
      })
      WALLET._push({ _id, key, address })
      return { _id, key, address }
    } catch (error) {
      console.log(error)
    }
  }
  static Create = async () => {
    try {
      let address = '',
        passPhrase = '',
        key = ''

      if (NativeBridge.isBridge()) {
        ;({ address, pass_phrase: passPhrase } = await NativeBridge.Send('CREATE_WALLET'))
        key = 'NATIVE_WALLET'
      } else {
        passPhrase = CRYPTO.GeneratePassphrase()
        const { privateKey } = create()
        key = CRYPTO.EncryptData(privateKey, passPhrase)
        const provider = new Wallet(privateKey)
        address = await provider.getAddress()
        console.log('Account Address: ', address) // Log the smart account address
      }
      let {
        result: { _id, isPrimary },
      } = await WalletService.Create({
        pass_phrase: passPhrase,
        address,
      })
      // createAndDownload(privateKey, "private_key.txt")
      WALLET._push({ _id, key, address })
      return { _id, key, address, isPrimary }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  static _getWalletPassPhrase = async (wallet: IWallet) => {
    const {
      result: { pass_phrase },
    } = await WalletService.Passphrase(wallet._id)
    return pass_phrase
  }
  static _getWalletPrivateKey = async (wallet: IWallet) => {
    const pass_phrase = await this._getWalletPassPhrase(wallet)
    let pk = CRYPTO.DecryptData(wallet.key, pass_phrase)
    return { pk, pass_phrase, address: wallet.address }
  }
  static _getWalletProvider = async (wallet: IWallet, chainId: CHAIN_ID[] = [CHAIN_ID.MAINNET]) => {
    const { pk: privateKey } = await WALLET._getWalletPrivateKey(wallet)
    return chainId?.map((chainId) => {
      const p = chainId ? NETWORKS_BY_CHAINID[chainId].rpc : undefined
      return new Wallet(privateKey, p)
    })
  }
  static _handleGaslessTx = async (wallet: IWallet, data: IDomainData[], breakdown: IBreakdown[], to: string = '', orderId: string) => {
    const network = (useAppStore as any).getState().network as INetworks
    const spender = CRAY_TRANSFER_ADDRESS[network]
    const deadline_time = deadline()
    const p = data.map(({ domainData: { chainId } }) => NETWORKS_BY_CHAINID[chainId].rpc)
    const erc20Contracts = data.map(({ domainData: { verifyingContract } }, i: number) => new ethers.Contract(verifyingContract, erc20ABI, p[i]))
    const nonces = await Promise.all(erc20Contracts.map((erc20Contract) => erc20Contract.nonces(wallet.address))) //.map((_) => Number(_))
    const values = breakdown.map(({ spend }, i: number) => {
      return {
        owner: wallet.address,
        spender,
        value: spend,
        nonce: nonces[i],
        deadline: deadline_time,
      }
    })
    values.reduce((obj: any, prop, i: number) => {
      const key = data[i].domainData.verifyingContract + String(prop.nonce)
      if (!obj[key]) {
        obj[key] = 0n
      }
      prop.nonce += obj[key]
      obj[key]++
      return obj
    }, {})

    let signatures: any
    if (NativeBridge.isBridge()) {
      const pass_phrase = await this._getWalletPassPhrase(wallet)
      const walletAddress = wallet.address
      signatures = await NativeBridge.Send('SEND_TX', { data, walletAddress, pass_phrase, gasless: true, values })
    } else {
      const provider = await WALLET._getWalletProvider(
        wallet,
        data.map(({ domainData: { chainId } }) => chainId)
      )

      signatures = await Promise.all(
        provider.map(async (wallet, i: number) => {
          let signature = await wallet.signTypedData(data[i].domainData, SIGN_TYPE, values[i])
          const r = signature.slice(0, 66)
          const s = '0x' + signature.slice(66, 130)
          const v = '0x' + signature.slice(130, 132)
          return { r, s, v }
        })
      )
    }
    const payload = signatures.map(({ r, s, v }: { r: string; s: string; v: bigint }, i: number) => ({
      chainId: data[i].domainData.chainId,
      token: data[i].domainData.verifyingContract,
      sender: wallet.address,
      receiver: to,
      amount: breakdown[i].spend,
      fee: '0',
      deadline: deadline_time.toString(),
      v,
      r,
      s,
    }))
    return PaymentService.BroadcastTransaction(orderId, payload)
  }
  static _handleGasTx = async (wallet: IWallet, data: any, orderId: string) => {
    const p = data.map(({ chain }: { chain: CHAIN_ID }) => NETWORKS_BY_CHAINID[chain].rpc)
    let feeData = await Promise.all(p.map((p: any) => p.getFeeData()))
    const gasPrice = feeData.map((_: any) => Number(_.gasPrice) * 80_000)
    const nativeBalance = await Promise.all(p.map((p: any) => p.getBalance(wallet.address)))
    const isLowGas = gasPrice.some((gas: any, i: number) => nativeBalance[i] < gas)
    if (isLowGas) {
      throw new Error("You don't have sufficient gas to complete this payment.")
    }
    let popTx: any, signTx: any
    if (NativeBridge.isBridge()) {
      const pass_phrase = await this._getWalletPassPhrase(wallet)
      const walletAddress = wallet.address
      ;({ popTx, signTx } = await NativeBridge.Send('SEND_TX', { data, walletAddress, pass_phrase }))
    } else {
      const provider = await WALLET._getWalletProvider(
        wallet,
        data.map((_: any) => _.chain)
      )
      popTx = await Promise.all(
        data.map(async (data: any, i: number) =>
          provider?.[i].populateTransaction({
            ...data,
            gasPrice: await (provider[i] as any).provider?.getFeeData()?.gasPrice,
          })
        )
      )
      popTx.reduce((obj: any, prop: any) => {
        if (!obj[prop.nonce]) {
          obj[prop.nonce] = 0
        }
        prop.nonce += obj[prop.nonce]
        obj[prop.nonce]++

        return obj
      }, {})
      signTx = await Promise.all(popTx.map((popTx: any, i: number) => provider?.[i].signTransaction(popTx)))
    }

    const payload = signTx.map((st: string, i: number) => {
      return { signature: st, chainId: popTx[i].chainId, token: popTx[i].to }
    })
    return PaymentService.BroadcastTransaction(orderId, payload)
  }
  static _sendTx = async (wallet: IWallet, payload: any, to?: string) => {
    const { data, breakdown, isGasless, orderId } = payload
    if (isGasless) {
      return WALLET._handleGaslessTx(wallet, data, breakdown, to, orderId)
      //
    } else {
      return WALLET._handleGasTx(wallet, data, orderId)
    }
  }
  static _signTx = async (wallet: IWallet, data: any) => {
    if (NativeBridge.isBridge()) {
      const walletAddress = wallet.address
      const pass_phrase = await this._getWalletPassPhrase(wallet)
      return NativeBridge.Send('SIGN_MEASAGE', { data, walletAddress, pass_phrase })
    } else {
      const provider = await WALLET._getWalletProvider(wallet)
      return provider?.[0].signMessage(data)
    }
  }
  static HandleWCRequest = async (wallet: IWallet, chainId: CHAIN_ID, payload: any) => {
    if (typeof payload === 'string') {
      return WALLET._signTx(wallet, payload)
    } else {
      const data = [{ ...payload, chain: chainId }]
      const breakdown = [payload]
      return WALLET._sendTx(wallet, { data, breakdown })
    }
  }
  static Send = async ({
    wallet,
    to,
    value,
    sourceChain,
    destinationChain,

    ENS,
  }: {
    options?: { gasPrice?: string }
    wallet: IWallet
    to: string
    value: string
    sourceChain: CHAIN_ID
    destinationChain?: number
    ENS?: boolean
  }) => {
    if (ENS) {
      const p = NETWORKS_BY_CHAINID[CHAIN_ID.MAINNET].rpc
      to = (await p.resolveName(to)) || ''
      if (to === '') {
        throw new Error("Can't resolve ENS")
      }
    }
    const { result } = await PaymentService.GetDataToSign({
      senderAddress: wallet.address,
      receiverAddress: to,
      sourceChain: sourceChain,
      destinationChain: destinationChain || sourceChain,
      amount: value, // Handling the converstion on backend
      orderType: OrderType.P2P,
    })
    if (!result) return
    return WALLET._sendTx(wallet, result, to)
  }
  // static SetupRecovery = async (password: string) => {
  //     const { _id: userId } = queryClient.getQueryData(["user"]) as any
  //     const wallets = Object.values(WALLET.GetAll() || {}).filter((_: any) => _.userId === userId)
  //     const { result } = await WalletService.SetupRecovery(wallets.map((_: any) => _._id))
  //     let keys = result
  //         .map(({ _id, pass_phrase }: any) => {
  //             const wallet = WALLET.Get(_id)
  //             const privateKey = CRYPTO.DecryptData(wallet.key, pass_phrase)
  //             // const encryptedPK = CRYPTO.EncryptData(privateKey, password)
  //             return privateKey
  //         })
  //         .join("###")
  //     const encryptedKey = CRYPTO.EncryptData(keys, password)
  //     const secret = toUint8Array(encryptedKey.normalize("NFKC"))
  //     const [userPart, serverPart] = await split(secret, 2, 2)
  //     const userPartHash = await sha256(buf2hex(userPart))
  //     await WalletService.SendServerSecret({
  //         serverPart: buf2hex(serverPart),
  //         hash: userPartHash,
  //     })
  //     const encryptedUserPart = CRYPTO.EncryptData(buf2hex(userPart), password)
  //     createAndDownload(encryptedUserPart, "recovery_file.txt")

  //     // //recovery
  //     // const DecryptUserData = CRYPTO.DecryptData(encryptedUserPart, password)
  //     // const reconstructed = await combine([hexStringToArrayBuffer(DecryptUserData), hexStringToArrayBuffer(buf2hex(serverPart))])
  //     // console.log(CRYPTO.DecryptData(arrayBuffertoString(reconstructed), password))
  // }
  static RecoverAccount = async (encryptedUserPart: string, password: string) => {
    try {
      const DecryptUserData = CRYPTO.DecryptData(encryptedUserPart, password)
      const hash = await sha256(DecryptUserData)
      const {
        result: { serverPart },
      } = await WalletService.GetServerSecret(hash)
      const reconstructed = await combine([hexStringToArrayBuffer(DecryptUserData), hexStringToArrayBuffer(serverPart)])
      alert(CRYPTO.DecryptData(arrayBuffertoString(reconstructed), password))
    } catch (error) {
      console.log(error)
      alert('something went wrong')
    }
  }
}
// function buf2hex(buffer: ArrayBuffer) {
//     // buffer is an ArrayBuffer
//     return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, "0")).join("")
// }
