import { get, post } from '@/utils/xhr'

class Chat {
  GetAll = async () => {
    return (await get(`/chat/`)).result
  }
  GetByUser = async (userId: string) => {
    return (await get(`/chat/${userId}`)).result
  }
  Send = (payload: { receiverUser: string; message: string }) => {
    return post('/chat/', payload)
  }
}
export const ChatService = new Chat()
