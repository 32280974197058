import { AuthService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useToast } from '@/cn/ui/use-toast'
import { Stepper, useStepperContext } from '@/ui'
import SuspenceLoader from '@/ui/suspence_loader'
import { SHOW, queryClient } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'
import { PasskeyUtil } from '@/utils/passkey'
import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import AuthOtpScreen from './otp_screen'

const AuthModule = () => {
  const [isSignup, setIsSignup] = useState(window.location.hash !== '#signin')
  const [loginWithPasskey, setLoginWithPasskey] = useState(true)
  const { handleSubmit, register } = useForm<{ email: string; name: string }>()
  const stepContext = useStepperContext() as any
  const { toast } = useToast()
  const mutation = useMutation(
    async (payload: { email: string; name: string }) => {
      const { email, name } = payload
      const { result: isUserExists } = await AuthService.IsUserExist(email)
      let is_login = false
      if (isUserExists) {
        is_login = true
        await AuthService.RequestLoginOtp(email)
      } else {
        is_login = false
        await AuthService.RequestRegisterOtp(email)
      }
      stepContext.setData({
        is_login,
        name,
        email,
      })
    },
    {
      onSuccess: () => {
        stepContext.next()
      },
      onError: (error: any) => {
        console.log(error)
        return toast({
          variant: 'destructive',
          title: error.message,
        })
      },
    }
  )

  const handleNext = (payload: { email: string; name: string }) => {
    const { email } = payload
    if (!email) {
      return toast({
        variant: 'destructive',
        title: 'Please enter email',
      })
    }
    mutation.mutate(payload)
  }
  const continueWithGoogle = async (googleAuthToken: string) => {
    try {
      await AuthService.ContinueWithGoogle(googleAuthToken)
      await queryClient.invalidateQueries(['user'])
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (NativeBridge.isBridge()) return
    const google = (window as any).google
    google.accounts.id.initialize({
      client_id: import.meta.env.VITE_GOOGLE_AUTH_CLIENT_ID,
      callback: (res: any) => continueWithGoogle(res.credential),
    })
    google.accounts.id.prompt()
    google.accounts.id.renderButton(document.getElementById('googleLoginBtn'), {
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      width: '350px',
    })
  }, [loginWithPasskey])
  const nativeContinueWithGoogle = useMutation(async () => {
    try {
      let res = (await NativeBridge.Send('CONTINUE_WITH_GOOGLE')) as any
      const urlParams = new URLSearchParams(res)
      const googleAccessToken = urlParams.get('access_token') || ''
      await AuthService.NativeContinueWithGogle(googleAccessToken)
      await queryClient.invalidateQueries(['user'])
    } catch (error) {
      console.log(error)
    }
  })
  const passkeyUsers = PasskeyUtil.LoggedUser()

  const passkeyLoginMutation = useMutation((_id: string) => PasskeyUtil.Login(_id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
    onError: (error: any) => {
      toast({
        title: error.message,
        variant: 'destructive',
      })
    },
  })
  const passkeyUser = passkeyUsers?.at(-1)
  if (passkeyUser && loginWithPasskey) {
    return (
      <div className="flex flex-col h-full bg-background text-foreground">
        <div className=" mt-24 mx-4 mb-4 flex flex-col gap-4 justify-end">
          <h2>Welcome Back.</h2>
        </div>
        <div className=" mx-4 flex flex-col gap-2">
          <h3> {passkeyUser.name || passkeyUser.username}</h3>
        </div>
        <div className="flex flex-col gap-4 px-4 mt-12">
          <Button loading={passkeyLoginMutation.isLoading} onClick={() => passkeyLoginMutation.mutate(passkeyUser._id)}>
            Sign In
          </Button>
          <span className=" p-4 text-wowza-500 underline cursor-pointer" onClick={() => setLoginWithPasskey(false)}>
            Try another way
          </span>
        </div>
      </div>
    )
  }
  if (mutation.isLoading || nativeContinueWithGoogle.isLoading) {
    return <SuspenceLoader />
  }
  return (
    <Stepper context={stepContext}>
      <Stepper.Step>
        <form onSubmit={handleSubmit(handleNext)} className="flex flex-col gap-4 justify-center h-full bg-background text-foreground">
          <div className="mx-4 mb-4 flex flex-col gap-4 justify-end">
            <h3 className="text-center">{isSignup ? 'Sign up to create an account' : 'Enter your email'}</h3>
          </div>
          <div className=" pb-4 mx-4 flex flex-col gap-4">
            {/* <Label>Email</Label> */}
            {isSignup ? <Input {...register('name')} placeholder="Enter your name" className="placeholder:text-center text-center" /> : null}
            <Input {...register('email')} placeholder="Enter email address" className="placeholder:text-center text-center" />
          </div>

          <div className="px-4 flex flex-col gap-4">
            <Button loading={mutation.isLoading} className="w-full" variant="secondary">
              {isSignup ? 'Sign Up' : 'Login'}
            </Button>
            <div className="flex items-center gap-2 px-4">
              <div className="h-[1px] w-full bg-[#ADADAD]" />
              Or
              <div className="h-[1px] w-full bg-[#ADADAD]" />
            </div>
            {NativeBridge.isBridge() ? (
              <Button type="button" loading={nativeContinueWithGoogle.isLoading} onClick={() => nativeContinueWithGoogle.mutate()} className="w-full ">
                <img src="/icons/google.png" className="w-5 mr-2 bg-white p-[3px] rounded-full" /> {isSignup ? 'Sign up' : 'Sign in'} with Google
              </Button>
            ) : (
              <div className=" space-y-4 flex justify-center">
                <div id="googleLoginBtn"></div>
              </div>
            )}
          </div>

          <span {...SHOW(isSignup)} className=" text-center">
            Already have an account?{' '}
            <button type="button" onClick={() => setIsSignup(false)} className="text-primary underline">
              Login
            </button>
          </span>
          <span {...SHOW(!isSignup)} className="text-center">
            Don't have any account?{' '}
            <button type="button" onClick={() => setIsSignup(true)} className="text-primary underline">
              Sign Up
            </button>
          </span>
          <span className="absolute bottom-4 text-xs left-1/2 -translate-x-1/2">Terms, privacy & Policy</span>
        </form>
      </Stepper.Step>
      <Stepper.Step children={<AuthOtpScreen />} />
    </Stepper>
  )
}
export default AuthModule
