import { get, post } from '@/utils/xhr'
import { startAuthentication } from '@simplewebauthn/browser'

class Passkey {
  RegisterOptions = () => {
    return get('/passkey/registration')
  }
  RegisterPasskey = (payload: any) => post('/passkey/registration', payload)

  AuthOptions = async (_id?: string) => {
    const {
      result: { options, userId },
    } = await get(`/passkey/auth-options`, {
      params: {
        _id,
      },
    })
    return {
      asseResp: await startAuthentication(options),
      userId,
    }
  }
}
export const PasskeyService = new Passkey()
