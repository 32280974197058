import { HistoryService } from '@/api'
import UserProfile from '@/assets/icons/user-profile.svg'
import { Input } from '@/cn/ui/input'
import { REFETCH_INTERVAL } from '@/constants'
import { useContactMap } from '@/hooks'
import { ITx } from '@/interface'
import { popup } from '@/popup_handler'
import { DP } from '@/ui'
import { localizeFiat, prettifyAddress } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { ArrowLeft, Search } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

const Transactions = () => {
  const transactions = useQuery(['transactions'], () => HistoryService.Get(), {
    refetchInterval: REFETCH_INTERVAL,
  })
  const contacts = useContactMap()
  const [q, setQ] = useState('')
  const filteredItems = useMemo(() => {
    const tx =
      transactions.data?.map((tx: ITx) => {
        const remoteUserAddrss = tx.direction ? tx.receiverAddress : tx.senderAddress

        const remoteUser = remoteUserAddrss in contacts ? contacts[remoteUserAddrss].name : prettifyAddress(remoteUserAddrss)
        return {
          ...tx,
          remoteUser,
          isSelf: tx.receiverAddress === tx.senderAddress,
          direction: tx.senderUser !== undefined ? 0 : 1,
          time: dayjs(tx.createdAt).format('DD MM YYYY  DD MMMM'),
        }
      }) || []
    return tx.reduce((obj: any, prop: any) => {
      const day = dayjs(prop.createdAt).format('DD MMM YYYY')
      if (!obj[day]) {
        obj[day] = []
      }
      obj[day].push(prop)
      return obj
    }, {})
  }, [q, transactions.data])

  return (
    <div className="flex flex-col h-full bg-background text-foreground pt-2">
      <div className="flex flex-col flex-grow overflow-scroll  w-full h-full pb-4">
        <div className="flex items-center bg-background gap-4 text-foreground sticky top-0 p-4 z-10">
          <Link to="../" className="rounded-full p-1">
            <ArrowLeft />
          </Link>
          <p className="pl-4 text-lg font-bold">History</p>
        </div>

        <div className="px-4 py-4 bg-background z-1 ">
          {transactions.data?.length > 1 ? <Input className="ring-offset-background-secondary" value={q} onChange={(e) => setQ(e.target.value)} icon={<Search />} placeholder="Search..." /> : null}
        </div>

        <div className="flex flex-col ">
          {Object.keys(filteredItems).map((date) => (
            <div key={date} className="flex flex-col">
              <div className="sticky top-[63px] flex justify-between py-2 input-bg px-4  bg-zinc-100 z-1">{date}</div>
              <div className="flex flex-col ">
                {filteredItems[date]?.map((tx: any) => {
                  return (
                    <button key={tx._id} onClick={() => popup.open('tx_history', tx)} className="flex p-4 gap-2  justify-between  text-sm md:hover:bg-gray-100 active:bg-gray-200/70">
                      <div className="flex items-center gap-4">
                        {tx.txUser?.name || tx.txUser?.username ? (
                          <DP name={tx.txUser.name || tx.txUser?.username} size="42px" className="text-[24px]" />
                        ) : (
                          <img className="h-10 w-10 rounded-full object-cover" src={UserProfile} />
                        )}
                        <div className="flex flex-col justify-between items-start ">
                          <p className=" capitalize"> {tx.txUser?.name || tx.txUser?.username || prettifyAddress(tx.txUser)} </p>
                          <span className="">{dayjs(tx.createdAt).format('hh:mm a')}</span>
                        </div>
                      </div>
                      <div className="flex justify-between gap-2 items-center">
                        <h5 className={' truncate max-w-48 sh ' + (tx.direction ? 'text-green-500' : 'text-foreground')}>
                          {tx.direction ? '+' : ''}
                          {localizeFiat(tx.amount)}
                        </h5>
                      </div>
                    </button>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Transactions
