import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import SendMoney from './send'
import ValidateSend from './validate_send'
import SendSuccess from './send_success'

const SendHome = () => {
  const location = useLocation()
  if (!location.state) {
    return <Navigate to="/dashboard" />
  }
  return (
    <Routes>
      <Route index element={<SendMoney />} />
      <Route path="validate-send" element={<ValidateSend />} />
      <Route path="send-success" element={<SendSuccess />} />
    </Routes>
  )
}
export default SendHome
