import { get } from '@/utils'

class Balance {
  Get = (data: any) =>
    get(`/balances`, {
      params: {
        ...data,
      },
    })
}
export const balanceService = new Balance()
