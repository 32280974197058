import { AuthService, FCMService } from '@/api'
import { useAppStore } from '@/store'
import SuspenceLoader from '@/ui/suspence_loader'
import { ACTIVE_CURRENCY, getFCMToken, queryClient } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'
import { useQuery } from '@tanstack/react-query'
import { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ModuleAuthenticator } from '../hooks'
import AuthModule from './auth'
import NativeAuthRedirect from './auth/native_auth_redirect'
import Dashbaord from './dashboard'
import SetupPasskey from './dashboard/setup-passkey'
import SetupUsername from './dashboard/setup-username'
import Home from './home'
import SubmitReferralCode from './dashboard/submit-referral-code'
const refetchTransactions = () => {
  queryClient.invalidateQueries(['transactions'])
}
const AppRouter = () => {
  const { loadWallets, resetWallets } = useAppStore((state) => state) as any
  const user = useQuery(['user'], () => AuthService.Status())
  const IS_PASSKEY_SETUP = window.localStorage.getItem(`passkey:${user.data?._id}`) === 'true' ? true : false
  useEffect(() => {
    if (!user || !user.data?._id) return
    var channel = (window as any).pusher.subscribe(user.data._id)
    channel.bind('TRANSACTION', refetchTransactions)
    return () => channel.unbind('TRANSACTION', refetchTransactions)
  }, [user])
  useEffect(() => {
    if (!user.data) {
      resetWallets()
    } else {
      loadWallets()
      if (!NativeBridge.isBridge()) {
        getFCMToken()
          .then((token: any) => {
            FCMService.SYNC(token)
          })
          .catch(console.log)
      } else if ((window as any).EXPO_TOKEN) {
        FCMService.SYNC((window as any).EXPO_TOKEN)
      }
    }
  }, [user.data])
  useEffect(() => {
    if (!user.data) return

    ACTIVE_CURRENCY.conversion_rate = user.data.default_currency.conversion_rate
    ACTIVE_CURRENCY.currency = user.data.default_currency.name
    ACTIVE_CURRENCY.symbol = user.data.default_currency.symbol
  }, [user.data])
  if (user.data) {
    if (user.data?.rewardProfile?.submittedReferralCode === false) {
      return <SubmitReferralCode />
    } else if (!user.data.username) {
      return <SetupUsername username={user.data.username} name={user.data.name} default_chain={user.data.default_chain} default_currency={user.data.default_currency.name} />
    } else if (!IS_PASSKEY_SETUP) {
      return <SetupPasskey />
    }
  }

  return (
    <ModuleAuthenticator user={user.data}>
      <Suspense fallback={<SuspenceLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<AuthModule />} />
          <Route path="/dashboard/*" element={<Dashbaord />}>
            <Route index element={<Dashbaord />} />
          </Route>
          <Route path="/native-auth" element={<NativeAuthRedirect />} />
        </Routes>
      </Suspense>
    </ModuleAuthenticator>
  )
}
export default AppRouter
