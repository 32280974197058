import { Button } from '@/cn/ui/button'
import { useToast } from '@/cn/ui/use-toast'
import { IToken } from '@/interface'
import { useAppStore } from '@/store'
import { DP, Modal } from '@/ui'
import SuspenceLoader from '@/ui/suspence_loader'
import { SHOW, WALLET, localizeFiat, parseErrorMessage, prettifyAddress, queryClient } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { ArrowLeft } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router-dom'
const ERROR_MAP = {
  'Error: insufficient funds': 'Error: insufficient Gas fee',
  'transfer amount exceeds balance': 'Error: insufficient balance',
  '429': 'App has exceeded its compute units per second capacity',
  'replacement fee too low': 'Replacement fee too low',
  'Low Gas Fee': 'insufficient Gas fee',
  "You don't have sufficient gas": "You don't have sufficient gas to complete this payment.",
}
const ValidateSend = () => {
  const location = useLocation()
  const props = location.state
  const navigate = useNavigate()
  const handleBack = () =>
    navigate('/dashboard/send-money', {
      state: props.contact,
    })
  const isGasTx = props.tokenBreakdown.some((_: IToken) => !_.gasless)
  const { wallet } = useAppStore((state) => state) as any
  const { address, name } = props.contact || {}
  const { toast } = useToast()
  const handleSend = useMutation(
    async () => {
      if (!address) return
      return WALLET.Send({
        wallet,
        to: address,
        value: String(props.amount),
        sourceChain: props.fromChain,
        destinationChain: props.toChain,
        // gas,
        // ENS,
      })
    },
    {
      onSuccess: async ({ result }) => {
        await queryClient.setQueryData(['transactions', address], (data: any) => {
          return [result, ...data]
        })
        navigate('../send-success', {
          state: {
            address: address,
            username: props.username,
            name: props.contact?.name,
            userId: props.userId,
          },
        })
      },
      onError: (error) => {
        console.log(error)
        ;(window as any).posthog.capture('SEND_FAILED', { error: JSON.stringify(error, Object.getOwnPropertyNames(error)) })
        toast({
          variant: 'destructive',
          title: parseErrorMessage(error, ERROR_MAP),
        })
      },
    }
  )
  if (handleSend.isLoading) {
    return <SuspenceLoader />
  }
  return (
    <Modal navClassName="px-4" className={`flex flex-col  h-full transition-all text-foreground duration-150 p-0`}>
      <div className="p-4 flex items-center gap-2">
        <button onClick={handleBack} className="flex items-center justify-center cursor-pointer hover:bg-background rounded-full p-2 active:opacity-70">
          <ArrowLeft />
        </button>
        <p className="font-bold">Confirm Pay</p>
      </div>
      <div className="flex flex-col justify-center items-center gap-8 px-4 flex-grow ">
        {props.contact?.name || props.user_name ? <DP name={props.contact?.name || props.user_name} size="42px" className="text-[24px]" /> : null}
        <div className="flex flex-col gap-4 items-center">
          <p className=" text-[18px] font-medium">Paying {!props.contact?.name ? ' Unknown' : props.contact?.name}</p>
          <p className="text-[18px]" {...SHOW(!!props.username)}>
            CPI ID: {props.username}@cray
          </p>
          <p className=" tracking-[-2%]">{prettifyAddress(address)}</p>
          <h2>{localizeFiat(props.amount)}</h2>
          <p className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 capitalize" {...SHOW(!!props.remark)}>
            {props.remark}
          </p>
        </div>
        <div className="flex flex-col rounded-md ring ring-background/10 p-4 w-full  text-sm  divide-y divide-foreground/10 shadow-xl text-foreground/80">
          <div className="flex justify-between py-2">
            <div>To</div>
            <div>{name || prettifyAddress(address)}</div>
          </div>
        </div>
        <Button loading={handleSend.isLoading} className="w-full" onClick={() => handleSend.mutate()}>
          Pay
        </Button>
        <p {...SHOW(isGasTx)} className="text-red-500">
          This transaction requires a gas fee.
        </p>
      </div>
    </Modal>
  )
}
export default ValidateSend
