import { post, put, get } from '@/utils/xhr'
import { PasskeyService } from './passkey'
import { NativeBridge } from '@/utils/native_bridge'
import { queryClient } from '@/utils'

class Wallet {
  GetAll = () => get('/wallet')
  Create = async ({ pass_phrase, address }: { pass_phrase: string; address: string }) => {
    return post('/wallet', { pass_phrase, address })
  }
  Passphrase = async (walletId: string) => {
    if (NativeBridge.isBridge()) {
      const { _id: userId } = queryClient.getQueryData(['user']) as any
      let password = (await NativeBridge.Send('GET_NATIVE_PASSWORD', userId)) as string
      return post(`wallet/passphrase-by-nativepassword/${walletId}`, {
        password,
      })
    } else {
      const { asseResp } = await PasskeyService.AuthOptions()
      return post(`/wallet/${walletId}`, {
        payload: asseResp,
      })
    }
  }
  SetupRecovery = async (walletIds: string[]) => {
    const { asseResp } = await PasskeyService.AuthOptions()
    return post(`/recovery/init-recovery`, {
      payload: asseResp,
      wallets: walletIds,
    })
  }
  SendServerSecret = async (payload: any) => {
    return post(`/recovery/save-server-secret`, payload)
  }
  GetServerSecret = async (hash: string) => {
    return post(`/recovery/get-server-secret`, { hash })
  }
  GetWalletInfo = (walletId: string) => get(`/wallet/${walletId}`)
  UpdateWallet = (walletId: string, payload: any) => put(`/wallet/${walletId}`, payload)
  markBackupAsDone = (type: string) =>
    post(`/wallet/mark-as-backedup`, {
      type,
    })
  markWalletAsV2 = () => post(`/wallet/mark-as-v2`)
  GetDefaultWallet = () => get('/users')
}
export const WalletService = new Wallet()
