/**
 * Depricated
 *
 */
import { ContactService } from '@/api'
import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useContactSync } from '@/hooks'
import { IContact } from '@/interface'
import { DP, Loading } from '@/ui'
import { TitleBar } from '@/ui/titlebar'
import { GoogleContacts, HIDE, handleInvite, queryClient } from '@/utils'
import { NativeBridge } from '@/utils/native_bridge'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Search, Share } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SelectContact = () => {
  const [q, setQ] = useState('')
  // const onChange = (contact: IContact) => popup.open("chat", contact)
  const contacts = useQuery(['contacts'], () => ContactService.GetAll())
  const navigate = useNavigate()
  const handleSelect = async (account: IContact) => {
    if (account.exists) {
      navigate('/dashboard/send-money', { state: account })
    } else {
      handleInvite()
    }
  }
  const handlePull = useMutation(
    async () => {
      const contact = new GoogleContacts()
      await contact.Authenticate()
      const data = await contact.PullContact()
      await ContactService.Add(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['contacts'])
      },
    }
  )
  useContactSync()
  return (
    <div title="Select Contact" className={`flex flex-col h-full transition-all duration-150 `}>
      <TitleBar title="Select Contact" navClassName="p-4" />
      <div className="w-full flex flex-col gap-2  overflow-scroll flex-grow px-4">
        {contacts.isLoading ? <Loading /> : null}
        {contacts.data?.length > 1 ? (
          <div className="px-4">
            <Input className=" " value={q} onChange={(e) => setQ(e.target.value)} icon={<Search className="" />} placeholder="Search..." />{' '}
          </div>
        ) : null}

        {!NativeBridge.isBridge() && contacts.data?.length === 0 ? (
          <Button loading={handlePull.isLoading} variant="secondary" onClick={() => handlePull.mutate()} className="w-full">
            <img src="/icons/people-api.png" className="w-4 mr-4" /> Sync with your Google Contacts
          </Button>
        ) : null}
        <div className="flex flex-col gap-2 mt-4">
          {[...(contacts.data || [])]
            ?.sort((a) => (a?.exists ? -1 : 1))
            ?.filter((contact) => contact?.name?.toLowerCase().includes(q.toLowerCase()))
            .map((contact: IContact) => (
              <button
                onClick={() => handleSelect(contact)}
                // state={contact}
                className={`flex items-center  justify-between gap-4 bg-background shrink-0 hover:bg-gray-100 active:bg-primary/20 rounded-[8px] p-4 cursor-pointer disabled:opacity-15 `}
              >
                <DP name={contact.name} size="42px" className="text-[24px]" />
                <div className="flex justify-between w-full gap-1 items-start">
                  <span className="">{contact?.name}</span>

                  <div className="flex items-center gap-2 text-gray-500" {...HIDE(contact?.exists || false)}>
                    <Share size={18} />
                    Invite
                  </div>
                </div>
              </button>
            ))}
        </div>
      </div>
    </div>
  )
}
export default SelectContact
