import { CHAIN_ID } from '@/constants'
import { get, post } from '@/utils/xhr'

class Auth {
  Status = async () => {
    return (await get(`/auth/status`)).result
  }
  IsUserExist = (email: string) => {
    return get(`/auth/is-user-exists?email=${email}`)
  }
  RequestLoginOtp = (email: string) => {
    return post('/auth/login/generate-otp', { email })
  }
  Login = ({ email, otp }: { email: string; otp: string }) => {
    return post('/auth/login', { email, otp })
  }
  SetupNativePassword = (password: string) => {
    return post('/auth/setup-native-password', { password })
  }
  LoginWithNativePassword = (_id: string, password: string) => {
    return post('/auth/login-with-native-password', { _id, password })
  }
  LoginWithPasskey = ({ payload, _id }: { payload: any; _id: string }) => {
    return post('/auth/login/with-passkey', { payload, _id })
  }
  RequestRegisterOtp = (email: string) => {
    return post('/auth/register/generate-otp', { email })
  }
  Register = ({ email, otp, name }: { email: string; otp: string; name: string }) => {
    return post('/auth/register', { email, otp, name })
  }
  ContinueWithGoogle = (googleAuthToken: string) => {
    return post('/auth/login/with-google', { googleAuthToken })
  }
  NativeContinueWithGogle = (googleAccessToken: string) => {
    return post('/auth/login/native-with-google', { googleAccessToken })
  }
  Logout = () => {
    return post('/auth/logout')
  }
  UpdateProfile = (payload: { username?: string; name?: string; default_chain?: CHAIN_ID; default_currency?: string }) => post('/users/update-profile', payload)
}
export const AuthService = new Auth()
