export const MEssageComponent = ({ data, userId }: any) => {
  const IS_SENT_TX = data.senderUser._id === userId
  data.txUser = IS_SENT_TX ? data.receiverUser : data.senderUser
  return (
    <div className={`flex ${IS_SENT_TX ? ' justify-end' : ''}`}>
      <div className={`leading-1.5 flex  flex-col  rounded-b-xl ${IS_SENT_TX ? ' rounded-tl-xl' : ' rounded-tr-xl'} border-gray-200 bg-gray-100 p-4 mx-4`}>
        <p className="text-sm font-normal text-gray-900 ">{data.message}</p>
      </div>
    </div>
  )
}
