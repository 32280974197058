import React, { ReactNode, useMemo, useState } from 'react'
const Step = ({ children }: { children: ReactNode }) => {
  return children
}
export const useStepperContext = (data = {}) => {
  const [state, setState] = useState<any>({
    location: 0,
    data,
  })
  return useMemo(
    () => ({
      state,
      setState,
      steps: [],
      prev: () => {},
      next: () => {},
    }),
    []
  )
}
export const Stepper = ({ children, context }: { children: ReactNode; context: any }) => {
  const steps: React.ReactElement[] = []
  const handleNext = () => {
    if (context.state.location < steps.length - 1) {
      context.state.location += 1
      context.setState({ ...context.state })
    }
  }
  const handlePrev = () => {
    if (context.state.location > 0) {
      context.state.location -= 1
      context.setState({ ...context.state })
    }
  }
  const jump = (location: number) => {
    if (location < 0 || location >= context.steps.length) {
      throw 'invalid location'
    }
    context.state.location = location
    context.setState({ ...context.state })
  }

  context.steps = steps
  context.prev = handlePrev
  context.next = handleNext
  context.jump = jump
  context.setData = (data: any) => {
    if (typeof data === 'function') {
      data = data(context.state.data)
    }
    context.state.data = {
      ...context.state.data,
      ...data,
    }
    context.setState({ ...context.state })
  }

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return
    if (child.type === Step) {
      steps.push(child)
    }
  })
  const Component = steps[context.state.location].props.children.type
  const Props = steps[context.state.location].props.children.props
  return <Component {...Props} {...context} />
}
Stepper.Step = Step
