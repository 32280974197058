import { ContactService } from '@/api'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const useContactMap = () => {
  const contacts = useQuery(['contacts'], () => ContactService.GetAll())

  return useMemo(() => {
    if (!contacts.data) return {}
    return contacts.data
      .filter((_: any) => _.address)
      .reduce((obj: any, prop: any) => {
        obj[prop.address] = prop
        return obj
      }, {})
  }, [contacts.data])
}
