import { IPaymantPayload } from '@/interface'
import { get, post } from '@/utils'
export interface IPaymantBreakdownPayload {
  senderAddress: string
  receiverAddress: string
  amount: string
}
class Payment {
  GetDataToSign = (payload: IPaymantPayload) => post('/pay/create/', payload)
  getPaymentBreakdown = (payload: IPaymantBreakdownPayload) => post('/pay/payment-breakdown/', payload)
  GetUserAddressByCPID = (username: string) => get(`/users/cpid/${username}/account`)
  GetUserAddressByAddress = (address: string) => get(`/users/address/${address}/account`)
  BroadcastTransaction = (orderId: string, payload: any) => post(`/pay/broadcast/${orderId}`, payload)
}
export const PaymentService = new Payment()
