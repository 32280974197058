import QRCode from 'qrcode'
import { useEffect, useState } from 'react'
import { Loading } from '.'
import { twMerge } from 'tailwind-merge'

export const QR = ({ data, className }: { data: string; className?: string }) => {
  const [qr, setQr] = useState<string | null>(null)

  useEffect(() => {
    if (!data) return
    QRCode.toDataURL(data, {
      margin: 0,
    }).then((url: string) => {
      setQr(url)
    })
  }, [data])
  return qr ? <img src={qr} className={twMerge('w-[166px]', className)} /> : <Loading />
}
