import { get, post } from '@/utils/xhr'

class Reward {
  GetAll = () => {
    return get('/rewards')
  }
  SubmitReferralCode = (code: string) => {
    return post(`/rewards/submit-referral/`, { code })
  }
  GetReferralClaims = () => {
    return get(`/rewards/get-referral-claims/`)
  }
}
export const rewardService = new Reward()
