// src/components/ShowMoreList.tsx
import { ChevronDown, ChevronUp } from 'lucide-react'
import { useState } from 'react'

interface ShowMoreListProps {
  items: any[]
  maxItems: number
  renderItem: (item: any) => JSX.Element
}

const ShowMoreList = ({ items, maxItems, renderItem }: ShowMoreListProps) => {
  const [showAll, setShowAll] = useState(false)

  const shouldShowMoreButton = items.length > maxItems
  const displayedItems = showAll || !shouldShowMoreButton ? items : items.slice(0, maxItems - 1)

  return (
    <div>
      <div className="flex flex-wrap">
        {displayedItems.map(renderItem)}
        {!showAll && shouldShowMoreButton && (
          <button
            onClick={() => setShowAll(true)}
            className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
          >
            <div className="h-12 w-12 rounded-full object-cover border text-white flex items-center justify-center border-[#444444] bg-primary">
              <ChevronDown />
            </div>
            <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">More</span>
          </button>
        )}
        {showAll && shouldShowMoreButton && (
          <button
            onClick={() => setShowAll(false)}
            className="relative flex flex-col items-center justify-start w-20 gap-2 mt-4 text-primary md:hover:bg-gray-100 rounded-[16px] p-2 active:opacity-70 transition-all duration-100"
          >
            <div className="h-12 w-12 rounded-full object-cover border text-white flex items-center justify-center border-[#444444] bg-primary">
              <ChevronUp />
            </div>
            <span className="inline leading-4 text-center font-medium text-foreground text-sm h-4 truncate w-16">Less</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default ShowMoreList
