import { Button } from '@/cn/ui/button'
import { Modal } from '@/ui'
import { Check } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router-dom'
const SendSuccess = () => {
  const location = useLocation()
  const props = location.state
  const navigate = useNavigate()

  const handleClose = () => {
    close()
    navigate('/dashboard/chat', {
      state: props,
      replace: true,
    })
  }
  return (
    <Modal title="Sent Successfully" navClassName="px-4" className={`flex flex-col items-center justify-center h-full transition-all text-foreground duration-150`}>
      <div className="w-20 h-20 bg-primary flex items-center justify-center rounded-full mx-auto text-background">
        <Check size={48} />
      </div>
      <h4 className="text-center">Done</h4>
      <Button className="w-48 mx-auto mt-8" onClick={handleClose}>
        Okay
      </Button>
    </Modal>
  )
}
export default SendSuccess
