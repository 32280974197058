import { Button } from '@/cn/ui/button'
import { Input } from '@/cn/ui/input'
import { useEffect } from 'react'
// import { useAppStore } from "@/store"
import { Label } from '@/cn/ui/label'
import { useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { WalletService } from '@/api/wallet'
import { useForm } from 'react-hook-form'
import { ToggleButton } from '@/ui'
import { useToast } from '@/cn/ui/use-toast'
import { queryClient } from '@/utils'
import { useAppStore } from '@/store'

const UpdateAccountInfo = () => {
  // const { importWallet } = useAppStore((state) => state) as any
  const { loadWallets } = useAppStore((state) => state) as any

  const { toast } = useToast()
  const { watch, handleSubmit, setValue, reset } = useForm<{ name: string; isPrimary: boolean }>()
  const { accountId = '' } = useParams()
  const wallet = useQuery(['wallet', accountId], () => WalletService.GetWalletInfo(accountId))
  useEffect(() => {
    if (!wallet.data) return
    reset({ ...wallet.data.result })
  }, [wallet.data])
  const updateMutation = useMutation(({ name, isPrimary }: { name: string; isPrimary: Boolean }) => WalletService.UpdateWallet(accountId, { name, isPrimary }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['balance'])
      loadWallets()
      toast({
        title: 'Wallet updated',
      })
    },
  })
  const handleSave = ({ name, isPrimary }: { name: string; isPrimary: boolean }) => {
    updateMutation.mutate({ name, isPrimary })
  }
  const navigate = useNavigate()
  const handelBack = () => {
    if ((window as any).location_key === 'default') {
      // no back state found
      navigate('/dashboard/')
    } else {
      navigate(-1)
    }
  }
  return (
    <div className="h-full  bg-background text-foreground ">
      <div className="flex items-center gap-2">
        <button onClick={handelBack} className="p-[10px]">
          <ArrowLeft />
        </button>
        <p className="text-lg font-bold">Update Account</p>
      </div>
      <form onSubmit={handleSubmit(handleSave)} className=" py-8 flex justify-between flex-col gap-4 h-full  px-6">
        <ToggleButton value={watch('isPrimary')} onChange={(isPrimary: boolean) => setValue('isPrimary', isPrimary)} label="Primary Account" />
        <div className="flex flex-col gap-1">
          <h5>Name this account</h5>
          <p>What's the primary purpose of this account? This is just for you to see and won't be shared.</p>
        </div>
        <div className="flex-grow">
          <div className="grid w-full items-center gap-1.5">
            <Label className=" text-foreground-secondary" htmlFor="passphrase">
              Account name
            </Label>
            <div className="flex items-center gap-2 w-full">
              <Input onChange={(e) => setValue('name', e.target.value)} value={watch('name')} className=" bg-background-secondary ring-offset-background-secondary" placeholder="My Wowza account" />
              {/* <Button loading={updateMutation.isLoading}>Save</Button> */}
            </div>
          </div>
        </div>

        <Button loading={updateMutation.isLoading} variant="default" className="w-full mb-8">
          Update
        </Button>
      </form>
    </div>
  )
}
export default UpdateAccountInfo
